@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;400;600&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scrollbar-width: none;
}

.example::-webkit-scrollbar {
  display: none;
}

.html {
  scroll-behavior: smooth;
}